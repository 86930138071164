import { useAuth } from "../../contexts/AuthContext";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TrashIcon, CheckCircleIcon } from "@heroicons/react/outline";

export default function Panier() {
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    const [cart, setCart] = useState([]);
    const [totalCart, setTotalCart] = useState({
        totalQuantity: 0,
        totalPrice: 0
    });

    useEffect(() => {
        if (!currentUser) {
            navigate("/login");
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        if (!localStorage.getItem("cart")) {
            localStorage.setItem("cart", JSON.stringify([]));
        }
        const cart = JSON.parse(localStorage.getItem("cart"));
        setCart((cart));

        cart.map((product) => {
            setTotalCart((prev) => {
                return {
                    totalQuantity: prev.totalQuantity + product.quantity,
                    totalPrice: prev.totalPrice + product.price * product.quantity
                }
            })
        })
    }, [])

    useEffect(() => {
        setTotalCart({
            totalQuantity: 0,
            totalPrice: 0
        })
        cart.map((product) => {
            setTotalCart((prev) => {
                return {
                    totalQuantity: prev.totalQuantity + product.quantity,
                    totalPrice: prev.totalPrice + product.price * product.quantity
                }
            })
        })
    }, [cart])

    const removeFromCart = (product) => {
        let products = [];
        if (localStorage.getItem('cart')) {
            products = JSON.parse(localStorage.getItem('cart'));
        }
        const filtered = products.filter((p) => {
            return p.product !== product.product;
        })
        setCart(filtered);
        localStorage.setItem('cart', JSON.stringify(filtered));
    }

    function handleDelete() {
        localStorage.removeItem("cart");
        setCart([]);
        setTotalCart({
            totalQuantity: 0,
            totalPrice: 0
        })
    }

    return (
        <>
            <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div className="text-center">
                        {!cart.length && (
                            <>
                                <Link
                                    to="/"
                                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Votre panier est vide !<br />
                                    Retour à la boutique
                                </Link>
                            </>
                        )}
                        {cart.length > 0 && (
                            <>
                                <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
                                    Votre panier
                                </h2>
                                <br />
                                <div className="grid grid-cols-4 gap-4 font-medium justify-items-center">
                                    <div className="text-black dark:text-white justify-self-start text-left">Produit</div>
                                    <div className="text-black dark:text-white">Quantité</div>
                                    <div className="text-black dark:text-white">Prix</div>
                                    <div className="justify-self-end">
                                        <TrashIcon className="h-5 w-5 group-hover:text-white text-black dark:text-white" aria-hidden="true" />
                                    </div>
                                </div>
                                {Object(cart).map((product, key) => {
                                    return (
                                        <div key={key} className="grid grid-cols-4 gap-4 justify-items-center">
                                            <div className="text-black dark:text-white justify-self-start text-left">{product.product}</div>
                                            <div className="text-black dark:text-white">{product.quantity}</div>
                                            <div className="text-black dark:text-white">{product.price.toFixed(2)}€</div>
                                            <button className="text-black dark:text-white justify-self-end" onClick={() => removeFromCart(product)}>
                                                <TrashIcon className="h-5 w-5 group-hover:text-white text-black dark:text-white" />
                                            </button>
                                        </div>
                                    )
                                })}
                                <br />
                                <div className="flex justify-between">
                                    <p className="text-black dark:text-white">Nombre d'articles : {totalCart.totalQuantity}</p>
                                    <p className="text-black dark:text-white">À régler : {(Math.round((totalCart.totalPrice) * 100) / 100).toFixed(2)} €</p>
                                </div>
                                <br />
                                <div className="flex justify-between">
                                    <Link
                                        to="/"
                                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-1"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 text-white group-hover:text-white" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                        </svg>
                                        Continuer mes achats
                                    </Link>
                                    <button
                                        onClick={handleDelete}
                                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-1"
                                    >
                                        <TrashIcon className="h-5 w-5 text-white group-hover:text-white" aria-hidden="true" />
                                        Vider mon panier
                                    </button>
                                </div>
                                <div className="flex justify-between">
                                    <Link
                                        to="/checkout"
                                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-1"
                                    >
                                        <CheckCircleIcon className="h-5 w-5 text-white group-hover:text-white" aria-hidden="true" />
                                        Valider la commande
                                    </Link>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}