import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import snack from "../../assets/snack.png";
import drink from "../../assets/soda.png";
import { ShoppingCartIcon, HeartIcon } from "@heroicons/react/outline";
import "./Product.css";
import { getDatabase, ref, onValue, push, set, update } from "firebase/database";

const bgWhite = "bg-white dark:bg-gray-600 shadow-2xl rounded-lg p-4 m-4 text-black dark:text-white";
const bgGreen = "bg-green-300 dark:bg-green-500 shadow-2xl rounded-lg p-4 m-4 text-black dark:text-white";

export default function Product({ product, dbKey }) {

    const [favorites, setFavorites] = useState([]);
    const [background, setBackground] = useState(bgWhite);

    const db = getDatabase();
    const { currentUser } = useAuth();
    const userRef = ref(db, `users/${currentUser.uid}`);

    useEffect(() => {
        onValue(userRef, (snapshot) => {
            const data = snapshot.val();
            setFavorites(data?.favorites || []);
        });
    }, []);

    const getPlaceholderImage = (category) => {
        if (category === "snack") {
            return snack;
        }
        if (category === "drink") {
            return drink;
        }
    }

    const addToCart = (product, quantity, price, uuid, key) => {
        if (quantity === 0) {
            return;
        };
        let products = [];
        if (localStorage.getItem('cart')) {
            products = JSON.parse(localStorage.getItem('cart'));
        }
        products.push({
            'id': uuid,
            'product': product,
            'quantity': quantity,
            'price': price,
            'dbKey': dbKey
        });
        localStorage.setItem('cart', JSON.stringify(products));
        setBackground(bgGreen);
        setTimeout(() => {
            setBackground(bgWhite);
        }, 1000);
    }

    const [quantity, setQuantity] = useState(1);
    const onChangeQuantity = (e) => {
        if (e.target.value >= 0 && e.target.value <= product.stock) {
            setQuantity(Number(e.target.value));
        }
    }
    const onDecreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    }
    const onIncreaseQuantity = () => {
        if (quantity < product.stock) {
            setQuantity(quantity + 1);
        }
    }

    const handleFavorite = (event) => {
        event.preventDefault();
        let newFavorites = [];
        if (favorites.includes(product.uuid)) {
            newFavorites = favorites.filter((favorite) => favorite !== product.uuid);
        } else {
            newFavorites = [...favorites, product.uuid];
        }
        update(userRef, {
            favorites: newFavorites
        });
    }

    const isFavorite = favorites.includes(product.uuid);



    return (
        <div key={dbKey} className={background}>
            {/* Heart icon to add to favorites */}
            <div className="flex justify-end">
                <HeartIcon className={isFavorite ? "fav-heart h-6 w-6 fill-red-600" : "fav-heart h-6 w-6"} onClick={(event) => handleFavorite(event)} />
            </div>

            <h1 className="font-semibold">{product.name}</h1>
            <h1>{product.description}</h1>
            <h1>{product.stock} en stock</h1>
            <h1 className="pb-1">{product.price.toFixed(2)} €</h1>
            {product.image && (
                <img className="scale" style={{ display: "block", margin: "0 auto", height: "200px", width: "auto" }} src={product.image} alt="product" />
            )}
            {!product.image && (
                <img className="scale" style={{ display: "block", margin: "0 auto", height: "200px", width: "auto" }} src={getPlaceholderImage(product.category)} alt="product" />
            )}
            <div className="p-1" />
            <button className="bg-gray-400 dark:bg-gray-700 text-white font-bold py-2 px-4 rounded" onClick={onDecreaseQuantity}>
                -
            </button>
            <input type="number" className="w-20 h-10 border-gray-300 rounded-md shadow-sm dark:text-black text-center" value={quantity} onChange={(event) => onChangeQuantity(event)} disabled={product.stock == 0 ? true : false} />
            <button className="bg-gray-400 dark:bg-gray-700 text-white font-bold py-2 px-4 rounded" onClick={onIncreaseQuantity}>
                +
            </button>
            <div className="p-1" />
            <button className={product.stock == 0 ? "bg-grey-500 text-white font-bold py-3 px-16 rounded" : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-16 rounded"} onClick={() => addToCart(product.name, quantity, product.price, product.uuid, dbKey)} disabled={product.stock == 0 ? true : false}>
                <ShoppingCartIcon className="h-5 w-5" aria-hidden="true" />
            </button>
        </div>
    );
}