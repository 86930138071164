import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from "./contexts/AuthContext";
import Register from "./components/accounts/Register";
import Login from "./components/accounts/Login";
import AddProducts from "./components/layouts/admin/AddProducts";
import Panier from './components/layouts/Panier';
import Checkout from './components/layouts/Checkout';
import Profile from "./components/accounts/Profile";
import WithPrivateRoute from "./utils/WithPrivateRoute";
import ErrorMessage from "./components/layouts/ErrorMessage";
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import Home from './components/layouts/Home';
import ErrorPage from './components/layouts/ErrorPage';
import EasterEgg from './components/layouts/EasterEgg';
import Admin from './components/layouts/admin/Admin';
import StockManager from './components/layouts/admin/StockManager';
import OrdersManager from './components/layouts/admin/OrdersManager';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <ErrorMessage />
        <Routes>
          {/* HOME */}
          <Route path="/" element={<Home />} />
          {/* ACCOUNTS */}
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          {/* LAYOUTS */}
          <Route path="/panier" element={<Panier />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route
            path="/profile"
            element={
              <WithPrivateRoute>
                <Profile />
              </WithPrivateRoute>
            }
          />
          {/* ADMIN */}
          <Route path="/admin" element={<Admin />} />
          <Route path="/admin/addproducts" element={<AddProducts />} />
          <Route path="/admin/stockmanager" element={<StockManager />} />
          <Route path="/admin/ordersmanager" element={<OrdersManager />} />
          <Route path="/easter-egg" element={<EasterEgg />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        {/* margin bottom pour le footer */}
        <div className="mb-10"></div>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
