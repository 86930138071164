import { useAuth } from "../../../contexts/AuthContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, push, onValue } from "firebase/database";
import { getStorage, ref as stRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Product from "../../cards/Product";
import ProductManager from "../../cards/ProductManager";

export default function StockManager() {
    const navigate = useNavigate();
    const db = getDatabase();
    const storage = getStorage();

    const { currentUser } = useAuth();

    const [products, setProducts] = useState([]);

    useEffect(() => {
        if (!currentUser) {
            navigate("/login");
        }
        const productsRef = ref(db, "products");
        onValue(productsRef, (snapshot) => {
            const data = snapshot.val();
            setProducts(data);
        });
    }, [currentUser, navigate]);

    return (
        <>
            <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
                Gestion des stocks
            </h2>
            <div className="dark:text-white">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 text-center">
                    {products !== [] && Object.keys(products).map((p) => {
                        return (
                            <ProductManager key={p} id={p} product={products[p]} />
                        )
                    })}
                </div>
            </div>
        </>
    )
}