import { useAuth } from "../../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { PlusIcon, ClipboardCheckIcon, ShoppingBagIcon } from "@heroicons/react/outline";



export default function Admin() {

    const navigate = useNavigate();
    const { currentUser } = useAuth();

    const db = getDatabase();

    const [dbData, setDbData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        promo: "",
        role: "",
    });

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (!currentUser) {
            navigate("/login");
        }
    }, [currentUser, navigate]);

    useEffect(() => {
        if (currentUser !== null) {
            const userRef = ref(db, `users/${currentUser.uid}`);
            onValue(userRef, (snapshot) => {
                const data = snapshot.val();
                setDbData(data);
            });
        }
    }, []);

    useEffect(() => {
        if (dbData.role === "admin") {
            setIsAdmin(true);
        }
    }, [dbData.role]);

    return (
        <>
            <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
                Espace administrateur
            </h2>

            <h2 className="mt-4 text-2xl text-center tracking-tight font-light dark:text-white">
            <Link
                to="/admin/addproducts"
                className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none rounded-full text-lg p-2.5"
            >
                <PlusIcon className="h-8 w-8 inline-block" aria-hidden="true" />
                Ajouter des produits
            </Link>

            <br className="m-4"/>

            <Link
                to="/admin/stockmanager"
                className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none rounded-full text-lg p-2.5"
            >
                <ClipboardCheckIcon className="h-8 w-8 inline-block" aria-hidden="true" />
                Gestion des stocks
            </Link>

            <br className="m-4"/>

            <Link
                to="/admin/ordersmanager"
                className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none rounded-full text-lg p-2.5"
            >
                <ShoppingBagIcon className="h-8 w-8 inline-block" aria-hidden="true" />
                Suivi des commandes
            </Link>
            </h2>
        </>
    )


}