import { useState, useEffect, Fragment } from "react";
import { useAuth } from "../../contexts/AuthContext";
import snack from "../../assets/snack.png";
import drink from "../../assets/soda.png";
import { getDatabase, ref, onValue, push, set, remove, update } from "firebase/database";
import { CheckCircleIcon, PencilIcon, PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";

let SaveIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6">
            <path d="M21 12.4V7l-4-4H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h7.4l8.6-8.6zM15 15c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zM6 6h9v4H6V6zm13.99 10.25 1.77 1.77L16.77 23H15v-1.77l4.99-4.98zm3.26.26-.85.85-1.77-1.77.85-.85c.2-.2.51-.2.71 0l1.06 1.06c.2.2.2.52 0 .71z"></path>
        </svg>
    );
};

export default function ProductManager({ product, id, key }) {
    
    const [category, setCategory] = useState(product.category);
    const [productName, setProductName] = useState(product.name);
    const [productDescription, setProductDescription] = useState(product.description);
    const [productPrice, setProductPrice] = useState(product.price);

    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);

    const db = getDatabase();
    const { currentUser } = useAuth();

    const getPlaceholderImage = (category) => {
        if (category === "snack") {
            return snack;
        }
        if (category === "drink") {
            return drink;
        }
    }

    const [quantity, setQuantity] = useState(product.stock);
    const onChangeQuantity = (e) => {
        setQuantity(Number(e.target.value));
    }
    const onDecreaseQuantity = () => {
        if (quantity > 0) {
            setQuantity(quantity - 1);
        }
    }
    const onIncreaseQuantity = () => {
        setQuantity(quantity + 1);
    }

    const saveStock = () => {
        const productRef = ref(db, `products/${id}`);
        update(productRef, {
            stock: quantity
        });
        setNeedsSave(false);
    }

    const [needsSave, setNeedsSave] = useState(false);

    useEffect(() => {
        if (quantity !== product.stock) {
            setNeedsSave(true);
        } else {
            setNeedsSave(false);
        }
    }, [quantity])

    const onDelete = () => {
        setDeleteModal(true);
    }

    const onAcceptDelete = async () => {
        const productRef = ref(db, `products/${id}`);
        await remove(productRef);
    }

    const onEdit = () => {
        setEditModal(true);
    }

    const onAcceptEdit = async () => {
        const productRef = ref(db, `products/${id}`);
        await update(productRef, {
            name: productName,
            description: productDescription,
            price: productPrice,
            category: category,
        });
        setEditModal(false);
    }

    const onChangeField = (event) => {
        const { id, value } = event.target;
        switch (id) {
            case "category":
                setCategory(value);
                break;
            case "product-name":
                setProductName(value);
                break;
            case "product-description":
                setProductDescription(value);
                break;
            case "product-price":
                setProductPrice(Number(value));
                break;
            default:
                break;
        }
    };


    return (
        <>
            <div key={key} className="bg-white dark:bg-gray-600 shadow-2xl rounded-lg p-4 m-4 text-black dark:text-white">
                <h1 className="font-semibold">{product.name}</h1>
                {product.image && (
                    <img className="scale" style={{ display: "block", margin: "0 auto", height: "200px", width: "auto" }} src={product.image} alt="product" />
                )}
                {!product.image && (
                    <img className="scale" style={{ display: "block", margin: "0 auto", height: "200px", width: "auto" }} src={getPlaceholderImage(product.category)} alt="product" />
                )}
                <div className="p-1" />
                <button className="bg-gray-400 dark:bg-gray-700 text-white font-bold py-2 px-4 rounded" onClick={onDecreaseQuantity}>
                    -
                </button>
                <input type="number" className="w-20 h-10 border-gray-300 rounded-md shadow-sm dark:text-black text-center" value={quantity} onChange={(event) => onChangeQuantity(event)} />
                <button className="bg-gray-400 dark:bg-gray-700 text-white font-bold py-2 px-4 rounded" onClick={onIncreaseQuantity}>
                    +
                </button>
                <div className="p-1" />
                <button className={needsSave ? "bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-16 rounded" : "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-16 rounded"} onClick={() => saveStock()}>
                    {needsSave &&
                        <PencilIcon className="h-5 w-5" aria-hidden="true" />
                    }
                    {!needsSave &&
                        <CheckCircleIcon className="h-5 w-5" aria-hidden="true" />
                    }
                </button>
                <div className="p-1" />
                <div className="flex flex-row justify-center">
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded mr-2" onClick={onEdit}>
                        <PencilAltIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded" onClick={onDelete}>
                        <TrashIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            </div>
            <Transition.Root show={deleteModal} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setDeleteModal}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity dark:bg-gray-900 dark:bg-opacity-75" />
                        </Transition.Child>
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <div className="inline-block align-bottom bg-white dark:bg-gray-600 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white dark:bg-gray-600 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-white" title="Delete Product">
                                                Supprimer {product.name}
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500 dark:text-gray-400">
                                                Êtes-vous sûr(e) de vouloir supprimer ce produit ? Toutes vos données seront définitivement supprimées. Cette action est irréversible.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 dark:bg-red-700 text-base font-medium text-white hover:bg-red-700 dark:hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 dark:focus:ring-red-700 sm:ml-3 sm:w-auto sm:text-sm" onClick={onAcceptDelete}>
                                        Supprimer
                                    </button>
                                    <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-500 shadow-sm px-4 py-2 bg-white dark:bg-gray-600 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 dark:focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => setDeleteModal(false)}>
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <Transition.Root show={editModal} as={Fragment}>
                <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={setEditModal}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity dark:bg-gray-900 dark:bg-opacity-75" />
                        </Transition.Child>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <div className="inline-block align-bottom bg-white dark:bg-gray-600 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white dark:bg-gray-600 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-white" title="Delete Product">
                                                Modifier {product.name}
                                            </Dialog.Title>
                                            <form onSubmit={onAcceptEdit}>
                                                <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Catégorie du produit :</label>
                                                <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="category" value={category} onChange={(event) => onChangeField(event)}>
                                                    <option value="none">Sélectionner une catégorie</option>
                                                    <option value="snack">Snack</option>
                                                    <option value="drink">Boisson</option>
                                                </select>
                                                <label htmlFor="product-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nom du produit :</label>
                                                <input className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm" id="product-name" type="text" value={productName} placeholder="Nom du produit" onChange={(event) => onChangeField(event)} />
                                                <label htmlFor="product-description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description du produit :</label>
                                                <input className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm" id="product-description" type="text" value={productDescription} placeholder="Description" onChange={(event) => onChangeField(event)} />
                                                <label htmlFor="product-price" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Prix du produit :</label>
                                                <input className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm" id="product-price" type="number" value={productPrice} placeholder="Prix unitaire" onChange={(event) => onChangeField(event)} />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                    <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 dark:bg-red-700 text-base font-medium text-white hover:bg-red-700 dark:hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 dark:focus:ring-red-700 sm:ml-3 sm:w-auto sm:text-sm" onClick={onAcceptEdit}>
                                        Modifier
                                    </button>
                                    <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-500 shadow-sm px-4 py-2 bg-white dark:bg-gray-600 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 dark:focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => setEditModal(false)}>
                                        Annuler
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )

}