import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    useEffect(() => {
        if (!currentUser) {
            navigate("/login");
        }
    }, [currentUser, navigate]);

    return (
        <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div className="text-center">
                    <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
                        {currentUser && (
                            <>
                                Oops !
                                <Link
                                    to="/"
                                    className="text-blue-600 hover:underline dark:text-blue-500">
                                    Retour à l'accueil
                                </Link>
                            </>
                        )}
                    </h2>
                </div>
            </div>
        </div>
    )
}