import { useAuth } from "../../contexts/AuthContext"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, onValue, once, get } from "firebase/database";
import Product from "../cards/Product";
import snack from "../../assets/snack.png";
import drink from "../../assets/soda.png";

let selectedButtonStyle = "bg-gray-300 dark:bg-white shadow-md rounded-lg p-3 mx-2 my-3 text-black";
let unselectedButtonStyle = "bg-white dark:bg-gray-400 shadow-md rounded-lg p-3 mx-2 my-3 text-black";

export default function Home() {
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    const [products, setProducts] = useState({});
    const [cart, setCart] = useState([]);
    const [category, setCategory] = useState("");

    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem("cart"));
        if (cart !== null) {
            setCart((cart));
        }
    }, [])

    useEffect(() => {
        if (!currentUser) {
            navigate("/login");
        }
        const db = getDatabase();
        const productsRef = ref(db, "products");
        onValue(productsRef, (snapshot) => {
            const data = snapshot.val();
            setProducts(data);
        });
    }, [currentUser, navigate]);

    const objectMap = (obj, fn) =>
        Object.fromEntries(
            Object.entries(obj).map(
                ([k, v], i) => [k, fn(v, k, i)]
            )
        )

    useEffect(() => {
        if (category == "snack" || category == "drink") {
            const db = getDatabase();
            const productsRef = ref(db, "products");
            onValue(productsRef, (snapshot) => {
                const data = snapshot.val();
                const filtered = Object.keys(data).filter((p) => {
                    return data[p].category === category;
                })
                const filteredData = filtered.reduce((acc, curr) => {
                    return {
                        ...acc,
                        [curr]: data[curr]
                    }
                }, {})
                setProducts(filteredData);
            });
        }
        if (category == "") {
            const db = getDatabase();
            const productsRef = ref(db, "products");
            onValue(productsRef, (snapshot) => {
                const data = snapshot.val();
                setProducts(data);
            });
        }
        if (category == "favorites") {
            const db = getDatabase();
            const productsRef = ref(db, "products");
            get(productsRef).then((snapshot) => {
                const products = snapshot.val();

                const userRef = ref(db, `users/${currentUser.uid}`);
                onValue(userRef, (snapshot) => {
                    const user = snapshot.val();

                    const favorites = user.favorites || [];
                    let favoriteProducts = {};
                    const tempProducts = { ...products };
                    favorites.forEach((favorite) => {
                        objectMap(tempProducts, (product, key) => {
                            if (favorite == product.uuid) {
                                favoriteProducts = { ...favoriteProducts, [key]: product }
                            }
                        })
                    })
                    setProducts(favoriteProducts);
                })
            });
        }
    }, [category]);

    useEffect(() => {
        localStorage.setItem("cart", JSON.stringify(cart));
    }, [cart]);

    const handleSearch = (e) => {
        const db = getDatabase();
        const productsRef = ref(db, "products");
        onValue(productsRef, (snapshot) => {
            const data = snapshot.val();
            const filtered = Object.keys(data).filter((p) => {
                return data[p].name.toLowerCase().includes(e.target.value.toLowerCase());
            })
            const filteredData = filtered.reduce((acc, curr) => {
                return {
                    ...acc,
                    [curr]: data[curr]
                }
            }, {})
            setProducts(filteredData);
        });
    }

    return (
        <>
            <div className="dark:text-white">
                {/* Catégories */}
                <div className="flex justify-center">
                    <div>
                    <button className={category == "" ? selectedButtonStyle : unselectedButtonStyle} onClick={() => setCategory("")}>Tout</button>
                    </div>
                    <div>
                    <button className={category == "favorites" ? selectedButtonStyle : unselectedButtonStyle} onClick={() => setCategory("favorites")}>Favoris</button>
                    </div>
                    <div>
                    <button className={category == "snack" ? selectedButtonStyle : unselectedButtonStyle} onClick={() => setCategory("snack")}>Snacks</button>
                    </div>
                    <div>
                    <button className={category == "drink" ? selectedButtonStyle : unselectedButtonStyle} onClick={() => setCategory("drink")}>Boissons</button>
                    </div>
                </div>
                {/* Barre de recherche */}
                <div className="flex justify-center">
                    <form>
                        <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                        <div class="relative">
                            <div class="absolute inset-y-7 left-5 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </div>
                        </div>
                    </form>
                    <input className="block w-full mx-4 p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" type="search" placeholder="Recherche" onChange={handleSearch} />
                </div>
                {/* Produits */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-6 text-center">
                    {products !== [] && Object.keys(products).map((p) => {
                        return (
                            <Product key={p} dbKey={p} product={products[p]} />
                        )
                    })}
                </div>
            </div>
        </>
    )
}