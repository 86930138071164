import { useAuth } from "../../../contexts/AuthContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, push } from "firebase/database";
import { getStorage, ref as stRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export default function AddProducts() {
    const navigate = useNavigate();
    const db = getDatabase();
    const storage = getStorage();

    const { currentUser } = useAuth();

    const [category, setCategory] = useState("");
    const [productName, setProductName] = useState("");
    const [productDescription, setProductDescription] = useState("");
    const [productPrice, setProductPrice] = useState(undefined);
    const [productImage, setProductImage] = useState(undefined);
    const [productStock, setProductStock] = useState(undefined);

    useEffect(() => {
        if (!currentUser) {
            navigate("/login");
        }
    }, [currentUser, navigate]);

    const onChangeField = (event) => {
        const { id, value } = event.target;
        switch (id) {
            case "category":
                setCategory(value);
                break;
            case "product-name":
                setProductName(value);
                break;
            case "product-description":
                setProductDescription(value);
                break;
            case "product-price":
                setProductPrice(Number(value));
                break;
            case "product-stock":
                setProductStock(Number(value));
                break;
            default:
                break;
        }
    };

    const uploadImage = () => {
        if (!productImage) return;
        return new Promise((resolve, reject) => {
            const file = productImage;
            const storageRef = stRef(storage, "products/" + file.name);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                switch (snapshot.state) {
                    case 'paused':
                        break;
                    case 'running':
                        break;
                }
            }, (error) => {
            }, () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL);
                });
            });
        });
    };

    const generateUuid = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            let url = "";
            if (productImage) {
                url = await uploadImage();
            }
            const newProduct = {
                category: category,
                name: productName,
                description: productDescription,
                price: Number(productPrice),
                image: url,
                stock: productStock,
                uuid: generateUuid(),
            };
            await push(ref(db, "products/"), newProduct);
            navigate("/admin");
        } catch (error) {
        }
    };

    return (
        <>
            <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
                Ajouter un nouveau produit
            </h2>
            <div className="dark:text-white min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <form onSubmit={handleSubmit}>
                        
                            {/* <label htmlFor="category" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Catégorie du produit :</label> */}
                            <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="category" value={category} onChange={(event) => onChangeField(event)}>
                                <option value="none">Sélectionner une catégorie</option>
                                <option value="snack">Snack</option>
                                <option value="drink">Boisson</option>
                            </select>
                        {/* <label htmlFor="product-name">Nom du produit :</label> */}
                        <input className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm" id="product-name" type="text" value={productName} placeholder="Nom du produit" onChange={(event) => onChangeField(event)} />
                        {/* <label htmlFor="product-description">Description du produit :</label> */}
                        <input className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm" id="product-description" type="text" value={productDescription} placeholder="Description" onChange={(event) => onChangeField(event)} />
                        {/* <label htmlFor="product-price">Prix du produit :</label> */}
                        <input className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm" id="product-price" type="number" value={productPrice} placeholder="Prix unitaire" onChange={(event) => onChangeField(event)} />
                        {/* <label id="product-stock">Quantité en stock :</label> */}
                        <input className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm" id="product-stock" type="number" value={productStock} placeholder="Quantité en stock" onChange={(event) => onChangeField(event)} />
                        {/* <label htmlFor="product-image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image du produit :</label> */}
                        <input className="block w-full text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="product-image" type="file" onChange={(event) => setProductImage(event.target.files[0])} />
                        <button type="submit" className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Ajouter le produit</button>
                    </form>
                </div>
            </div>
        </>
    )

}