import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";

import { getDatabase, ref, onValue, set } from "firebase/database";

import OrderCard from "../cards/OrderCard";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Profile() {
  const navigate = useNavigate();
  const db = getDatabase();

  const [username, setUsername] = useState("");
  // const [avatars, setAvatars] = useState([]);
  // const [selectedAvatar, setSelectedAvatar] = useState();
  const [loading, setLoading] = useState(false);

  const [dbData, setDbData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    promo: "",
  });

  const [orderData, setOrderData] = useState({});

  const { currentUser, updateUserProfile, setError } = useAuth();
  const userRef = ref(db, `users/${currentUser.uid}`);
  const orderRef = ref(db, `orders/${currentUser.uid}`);

  useEffect(() => {
    if (userRef) {
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        setDbData(data);
      });
    }
    if (orderRef) {
      onValue(orderRef, (snapshot) => {
        const data = snapshot.val();
        setOrderData(data);
      });
    }
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await set(userRef, {
        firstname: dbData.firstname,
        lastname: dbData.lastname,
        email: dbData.email,
        phone: dbData.phone,
        promo: dbData.promo,
      });
      navigate("/");
    } catch (e) {
      setError("Failed to update profile");
    }

    setLoading(false);
  };

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="text-center">
            <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
              Mes commandes
            </h2>
            {orderData && Object.keys(orderData).reverse().map((order) => {
              return (
              <OrderCard date={orderData[order].date} products={orderData[order].products} status={orderData[order].status} totalPrice={orderData[order].totalPrice} />
              )
            })}
          </div>
        </div>
      </div>
      <div className="min-h-full flex items-center justify-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="text-center">
            <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
              Profil
            </h2>
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className="rounded-md shadow-sm -space-y-px">
              <input
                id="firstname"
                name="firstname"
                type="text"
                autoComplete="firstname"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Prénom"
                defaultValue={dbData.firstname ? dbData.firstname : ""}
                onChange={(e) => setDbData({ ...dbData, firstname: e.target.value })}
              />
            </div>
            <div className="rounded-md shadow-sm -space-y-px">
              <input
                id="lastname"
                name="lastname"
                type="text"
                autoComplete="lastname"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Nom"
                defaultValue={dbData.lastname && dbData.lastname}
                onChange={(e) => setDbData({ ...dbData, lastname: e.target.value })}
              />
            </div>
            <div className="rounded-md shadow-sm -space-y-px">
              <input
                id="email"
                name="email"
                type="text"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="email"
                defaultValue={dbData.email && dbData.email}
                onChange={(e) => setDbData({ ...dbData, email: e.target.value })}
              />
            </div>
            <div className="rounded-md shadow-sm -space-y-px">
              <input
                id="phone"
                name="phone"
                type="text"
                autoComplete="phone"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Téléphone"
                defaultValue={dbData.phone && dbData.phone}
                onChange={(e) => setDbData({ ...dbData, phone: e.target.value })}
              />
            </div>
            <div className="rounded-md shadow-sm -space-y-px">
              <input
                id="promo"
                name="promo"
                type="text"
                autoComplete="promo"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-gray-500 rounded-t-md bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 focus:z-10 sm:text-sm"
                placeholder="Promotion"
                defaultValue={dbData.promo && dbData.promo}
                onChange={(e) => setDbData({ ...dbData, promo: e.target.value })}
              />
            </div>
            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Mettre à jour
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}