import { LogoutIcon, PlusIcon, ShoppingCartIcon, UserIcon, AdjustmentsIcon } from "@heroicons/react/outline";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../../contexts/AuthContext";
import Logout from "../accounts/Logout";
import ThemeToggler from "./ThemeToggler";

import "./Header.css";

import { getDatabase, ref, onValue } from "firebase/database";

let BuildingStorefrontIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z" />
    </svg>
  );
};

export default function Header() {

  const [modal, setModal] = useState(false);

  const { currentUser } = useAuth();

  const db = getDatabase();

  const [dbData, setDbData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    promo: "",
    role: "",
  });

  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (currentUser !== null) {
      const userRef = ref(db, `users/${currentUser.uid}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        setDbData(data);
      });
    };
    setIsAdmin(false);
    if (dbData.role === "admin") {
      setIsAdmin(true);
    }
  },[currentUser, db, dbData.role]);

  return (
    <>
      <nav className="sticky top-0 z-50 px- px-2 sm:px-4 py-2.5 bg-gray-50 border-gray-200 dark:bg-gray-800 dark:border-gray-700 text-gray-900 text-sm rounded border dark:text-white">
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <Link to="/" className="flex">
            <span className="header-logo self-center text-lg font-semibold whitespace-nowrap text-gray-900 dark:text-white">
              <BuildingStorefrontIcon aria-hidden="true" /> BDe-shop
            </span>
          </Link>
          <div className="flex md:order-2">
            {currentUser && isAdmin && (
              <Link
                to="/admin"
                className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none rounded-full text-sm p-2.5"
              >
                <AdjustmentsIcon className="h-8 w-8" aria-hidden="true" />
              </Link>
            )}

            <ThemeToggler />

            {currentUser && (
              <>
                <Link
                  to="/panier"
                  className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none rounded-full text-sm p-2.5"
                >
                  <ShoppingCartIcon className="h-8 w-8" aria-hidden="true" />
                </Link>

                <Link
                  to="/profile"
                  className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none rounded-full text-sm p-2.5"
                >
                  <UserIcon className="h-8 w-8" aria-hidden="true" />
                </Link>

                <button
                  className="text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none rounded-full text-sm p-2.5"
                  onClick={() => setModal(true)}
                >
                  <LogoutIcon className="h-8 w-8" aria-hidden="true" />
                </button>
              </>
            )}
          </div>
        </div>
      </nav>
      {modal && <Logout modal={modal} setModal={setModal} />}
    </>
  );
}