import { useState, useEffect, Fragment } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { getDatabase, ref, onValue, push, set, remove, update } from "firebase/database";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

export default function OrderManager({ key, id, order }) {

    const [showOrderDetails, setShowOrderDetails] = useState(false);

    const [status, setStatus] = useState(order.status);

    const changeStatus = (e) => {
        setStatus(e.target.value);
    }

    const onChangeStatus = () => {
        const db = getDatabase();
        update(ref(db, `orders/${order.idCustomer}/${order.idOrder}`), {
            status: status
        });
    }

    return (
        <>
            <div className="bg-white dark:bg-gray-600 shadow-2xl rounded-lg p-4 m-4 text-black dark:text-white">
                <div className="flex flex-col justify-center items-center">
                    <div className="flex flex-row justify-between items-center w-full">
                        <div>{order.customer}</div>
                        <div>{order.date}</div>
                        <div>{order.status}</div>
                        <div><button onClick={() => setShowOrderDetails(!showOrderDetails)}>
                            {showOrderDetails ? <ChevronUpIcon className="h-8 w-8 inline-block" aria-hidden="true" /> : <ChevronDownIcon className="h-8 w-8 inline-block" aria-hidden="true" />}
                        </button></div>
                    </div>
                    {showOrderDetails && (
                        <div className="grid grid-cols-3 gap-4 font-medium justify-items-center">
                            <div className="text-black dark:text-white justify-self-start text-left">Produit</div>
                            <div className="text-black dark:text-white">Quantité</div>
                            <div className="text-black dark:text-white">Prix</div>
                        </div>
                    )}
                    {showOrderDetails && order.products.map(product => {
                        return (
                            <>
                                <div className="grid grid-cols-3 gap-4 justify-items-center">
                                    <div className="text-black dark:text-white justify-self-start text-left">{product.product}</div>
                                    <div className="text-black dark:text-white">{product.quantity}</div>
                                    <div className="text-black dark:text-white">{product.price.toFixed(2)}{"€"}</div>
                                </div>
                            </>
                        )
                    })}
                    {showOrderDetails && <>
                        <div className="grid grid-cols-3 gap-4 font-medium mt-1">
                            <div>Total</div>
                            <div>{order.totalPrice.toFixed(2)}{"€"}</div>
                        </div>
                    </>}
                    {showOrderDetails && <>
                        <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" name="status" id="status" onChange={changeStatus}>
                            <option defaultChecked value={order.status}>{order.status}</option>
                            <option value="En attente">En attente</option>
                            <option value="En cours de préparation">En cours de préparation</option>
                            <option value="Prêt">Prêt</option>
                            <option value="Terminé">Terminé</option>
                        </select>
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={onChangeStatus}>Changer le statut</button>
                    </>
                    }
                </div>
            </div>
        </>
    );
}