import React, { Fragment, useEffect, useState } from 'react';
import { CreditCardIcon, ClockIcon, CheckIcon } from "@heroicons/react/outline";
import { Link } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, push, update, get } from "firebase/database";
import { Dialog, Transition } from "@headlessui/react";

export default function Checkout() {

    const navigate = useNavigate();
    const { currentUser } = useAuth();

    const db = getDatabase();

    const [cart, setCart] = useState([]);
    const [preorderSuccessModal, setPreorderSuccessModal] = useState(false);
    const [enterCBModal, setEnterCBModal] = useState(false);
    const [cbSuccessModal, setCbSuccessModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState("");

    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem("cart"));
        setCart((cart));

    }, [])

    useEffect(() => {
        if (!currentUser) {
            navigate("/login");
        }
        const usersRef = ref(db, `users/${currentUser.uid}`);
        get(usersRef).then((snapshot) => {
            if (snapshot.exists()) {
                const user = snapshot.val();
                setUser(user.firstname + " " + user.lastname)
            }
        });
    }, [currentUser, navigate]);

    const onBookOrder = async () => {
        await saveOrderToDB("réservée");
        setPreorderSuccessModal(true);
    }

    const saveOrderToDB = async (status) => {
        const cart = JSON.parse(localStorage.getItem("cart"));
        const order = {
            customer: user,
            products: cart,
            date: new Date().toLocaleDateString("fr"),
            status: status,
            totalPrice: cart.reduce((acc, product) => acc + product.price * product.quantity, 0)
        }
        await push(ref(db, `orders/${currentUser.uid}`), order);
        removeCartFromStock();
        // setPreorderSuccessModal(true);
    }

    const removeCartFromStock = async () => {
        const cart = JSON.parse(localStorage.getItem("cart"));
        cart.map(async (product) => {
            const productRef = ref(db, `products/${product.dbKey}`);
            let dbStock;
            await get(productRef).then((snapshot) => {
                if (snapshot.exists()) {
                    const product = snapshot.val();
                    dbStock = product.stock;
                }
            });
            await update(productRef, {
                stock: dbStock - product.quantity
            })
        });
        localStorage.removeItem("cart");
        setCart([]);
    }

    const handleCBPayment = () => {
        setLoader(true);
        setTimeout(() => {
            saveOrderToDB("payée");
            setLoader(false);
            removeCartFromStock();
            setEnterCBModal(false);
            setCbSuccessModal(true);
        }, 2000);
    }

    const handleClosePaymentModal = () => {
        setCbSuccessModal(false);
        navigate("/profile");
    };

    const handleClosePreorderModal = () => {
        setPreorderSuccessModal(false);
        navigate("/profile");
    };

    return (
        <>
            <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div className="text-center">
                        <>
                            <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
                                Checkout
                            </h2>
                            <button className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-1' onClick={() => setEnterCBModal(true)}>
                                <CreditCardIcon className="h-5 w-5 text-white group-hover:text-white" aria-hidden="true" />
                                Payer maintenant avec CB
                            </button>
                            <button className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-1' onClick={onBookOrder}>
                                <ClockIcon className="h-5 w-5 text-white group-hover:text-white" aria-hidden="true" />
                                Réserver et payer plus tard
                            </button>
                            <Link
                                to="/panier"
                                className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 m-1'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 text-white group-hover:text-white" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                </svg>
                                Retour au panier
                            </Link>
                        </>
                    </div>
                </div>
            </div>
            <Transition.Root show={enterCBModal} as={Fragment}>
                <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={enterCBModal} onClose={setEnterCBModal}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity dark:bg-gray-900 dark:bg-opacity-75" />
                        </Transition.Child>
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">​</span>
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <div className="inline-block align-bottom bg-white dark:bg-gray-600 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                                <div className="bg-white dark:bg-gray-600 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                                Paiement par carte bancaire
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500 dark:text-white">
                                                    Veuillez entrer les informations de votre carte bancaire
                                                </p>
                                            </div>
                                            <div className="mt-2">
                                                <form>
                                                    <div className="grid grid-cols-1 gap-6">

                                                        <div className="col-span-6 sm:col-span-3">
                                                            <label htmlFor="card_number" className="block text-sm font-medium text-gray-700 dark:text-white">
                                                                Numéro de carte
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="card_number"
                                                                id="card_number"
                                                                autoComplete="card_number"
                                                                className="mt-1 p-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            />
                                                        </div>
                                                        <div className="col-span-6 sm:col-span-3">
                                                            <label htmlFor="card_expiration" className="block text-sm font-medium text-gray-700 dark:text-white">
                                                                Date d'expiration
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="card_expiration"
                                                                id="card_expiration"
                                                                autoComplete="card_expiration"
                                                                className="mt-1 p-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            />
                                                        </div>

                                                        <div className="col-span-6 sm:col-span-3">
                                                            <label htmlFor="card_cvc" className="block text-sm font-medium text-gray-700 dark:text-white">
                                                                Cryptogramme visuel
                                                            </label>
                                                            <input
                                                                type="text"
                                                                name="card_cvc"
                                                                id="card_cvc"
                                                                autoComplete="card_cvc"
                                                                className="mt-1 p-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                        <button
                                                            type="button"
                                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                            onClick={handleCBPayment}
                                                        >
                                                            {loader ? "Paiement en cours..." : "Payer"}
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                                            onClick={() => setEnterCBModal(false)}
                                                        >
                                                            Annuler
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <Transition.Root show={cbSuccessModal} as={Fragment}>
                <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={cbSuccessModal} onClose={setCbSuccessModal}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">​</span>
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 dark:bg-gray-800">
                                <div>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 dark:bg-green-600">
                                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                            Paiement effectué
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500 dark:text-gray-300">
                                                Votre paiement a bien été effectué. Votre commande sera traitée dans les meilleurs délais.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white dark:bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm" onClick={handleClosePaymentModal}>
                                        Mes commandes
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
            <Transition.Root show={preorderSuccessModal} as={Fragment}>
                <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={preorderSuccessModal} onClose={setPreorderSuccessModal}>
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">​</span>
                        <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enterTo="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 translate-y-0 sm:scale-100" leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6 dark:bg-gray-800">
                                <div>
                                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 dark:bg-green-600">
                                        <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                                            Réservation effectuée
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500 dark:text-gray-300">
                                                Votre réservation a bien été effectué et sera traitée dans les meilleurs délais.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white dark:bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm" onClick={handleClosePreorderModal}>
                                        Mes commandes
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}