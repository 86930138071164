import { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

export default function Footer() {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <div className={isExpanded == true ? "h-50 bg-gray-100 dark:bg-gray-900 fixed bottom-0 right-0 rounded-tl-lg border-t-2 border-l-2 border-black dark:border-white" : "h-10 bg-gray-100 dark:bg-gray-900 fixed bottom-0 right-0 rounded-tl-lg"}>
            <div className="max-w-md w-full space-y-8">
                <div className="grid grid-cols-2 gap-4 justify-items-start text-center">
                    <div className="px-2 mt-2 text-xl text-center tracking-tight font-light dark:text-white">
                        À propos du BDE
                    </div>
                    <div className="justify-self-end">
                        <button onClick={() => setIsExpanded(!isExpanded)} className="mt-4 text-xl text-center tracking-tight font-light dark:text-white">
                            {isExpanded == true ? <ChevronDownIcon className="h-8 w-8 inline-block" aria-hidden="true" /> : <ChevronUpIcon className="h-8 w-8 inline-block" aria-hidden="true" />}
                        </button>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-4 justify-items-start text-center">
                    <div className="px-2 py-1 text-left text-black dark:text-white">
                        Localisation :
                        <br />
                        1er étage, bureau 113
                        <br />
                        7 bis av. Robert Schuman
                        <br />
                        51100 Reims
                    </div>
                    <div className="px-2 py-1 text-left text-black dark:text-white">
                        Horaires d'ouverture :
                        <br />
                        Matin : 10h/10h30
                        <br />
                        Après-midi : 15h/15h30
                    </div>
                </div>
            </div>
        </div>
    )
}