import { useAuth } from "../../../contexts/AuthContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDatabase, ref, push, onValue, set } from "firebase/database";
import { getStorage, ref as stRef, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import OrderManager from "../../cards/OrderManager";

export default function OrdersManager() {
    const navigate = useNavigate();
    const db = getDatabase();
    const storage = getStorage();

    const { currentUser } = useAuth();

    const [ordersDB, setOrdersDB] = useState([]);
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        if (!currentUser) {
            navigate("/login");
        }
        const ordersRef = ref(db, "orders");
        onValue(ordersRef, (snapshot) => {
            const data = snapshot.val();
            setOrdersDB(data);
        });
    }, [currentUser, navigate]);

    useEffect(() => {
        {
            let temp = [];
            Object.keys(ordersDB).map((o) => {
                let customer = o;
                Object.keys(ordersDB[o]).map((p) => {
                    let order = p;
                    let item = ordersDB[o][p];
                    item["idCustomer"] = customer;
                    item["idOrder"] = order;
                    temp.push(item);
                })
            })
            setOrders(temp);
        }
    }, [ordersDB])

    return (
        <>
            <h2 className="mt-4 text-3xl text-center tracking-tight font-light dark:text-white">
                Suivi des commandes
            </h2>
            <div className="dark:text-white">
                <div className="grid grid-cols-1 text-center">
                    {orders !== [] && orders.reverse().map((o) => {
                        return (
                            <OrderManager key={o} id={o} order={o} />
                        )
                    })}
                </div>
            </div>
        </>
    )
}