import { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { getDatabase, ref, onValue, push, set, update } from "firebase/database";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

export default function OrderCard({ date, products, status, totalPrice }) {

    const [showOrderDetails, setShowOrderDetails] = useState(false);

    return (
        <>
            <div className="bg-white dark:bg-gray-400 shadow overflow-hidden sm:rounded-lg my-2">
                <div className="px-4 py-5 sm:px-6">
                    <div className="grid grid-cols-3 gap-4">
                        <div>{date}</div>
                        <div>Statut :{" "}{status}</div>
                        <div><button onClick={() => setShowOrderDetails(!showOrderDetails)}>
                            {showOrderDetails ? <ChevronUpIcon className="h-8 w-8 inline-block" aria-hidden="true" /> : <ChevronDownIcon className="h-8 w-8 inline-block" aria-hidden="true" />}
                        </button></div>
                    </div>
                    {showOrderDetails && (
                        <div className="grid grid-cols-3 gap-4 font-medium">
                            <div>Produit</div>
                            <div>Prix</div>
                            <div>Quantité</div>
                        </div>
                    )}
                    {showOrderDetails && products.map(product => {
                        return (
                            <>
                                <div className="grid grid-cols-3 gap-4">
                                    <div>{product.product}</div>
                                    <div>{product.price.toFixed(2)}{"€"}</div>
                                    <div>{product.quantity}</div>
                                </div>
                            </>
                        )
                    })}
                    {showOrderDetails && <>
                        <div className="grid grid-cols-3 gap-4 font-medium mt-1">
                            <div>Total</div>
                            <div>{totalPrice.toFixed(2)}{"€"}</div>
                        </div>
                    </>}
                </div>
            </div>
        </>
    )
}